
import Vue from "vue";

import http from "@/http";

import { User } from "@/interfaces";

import { mdiMagnify } from "@mdi/js";

export interface DataType {
  users: User[];
  query: {
    email: string;
  };
}

export default Vue.extend({
  components: {},
  data(): DataType {
    return {
      users: [],
      query: {
        email: "",
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "メールログ",
          disabled: true,
          to: "/maillogs",
        },
      ];
    },
    icon() {
      return {
        mdiMagnify,
      };
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { email } = this.$route.query;

      if (email) {
        this.query.email = email.toString();
      } else {
        return;
      }

      const { data } = await http.get<User[]>("users", {
        params: {
          email: this.query.email,
        },
      });

      this.users = data;
    },
    async submit() {
      await this.$router
        .replace({
          path: "/maillogs",
          query: {
            email: this.query.email,
          },
        })
        .catch(() => {});
    },
  },
});
